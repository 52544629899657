import { LitElement, html, css } from 'lit'
import Dialog from 'dialog'
import Menu from 'menu'
import {Themes, ThemeColors} from 'bui/util/color-scheme'
import 'bui/elements/text-divider'
import 'bui/presenters/form/controls/range-slider'
import Coll, {settings} from './models'
import device from 'bui/util/device'
import AppInstaller from 'bui/app/installer'

const ScrollDir = [
    {label: 'Vertical Natural', val: '', description: 'Scroll up to increase'},
    {label: 'Vertical', val: 'vert', description: 'Scroll down to increase'},
    {label: 'Horizontal', val: 'horz', description: 'Left to decrease, right to increase'},
]

const TouchDir = [
    {label: 'Horizontal', val: '', description: 'Left to decrease, right to increase'},
    {label: 'Vertical', val: 'vert', description: 'Up to decrese, Down to increase'},
]

customElements.define('s-settings', class extends LitElement{
    
    static get title(){ return 'Settings' }
    static get path(){ return 'settings' }
    static get id(){ return 'settings' }
    static get icon(){ return 'tune' }

    static get styles(){return css`
        :host {
            display: block;
            position:relative;
            padding: var(--gutter);
            padding-top: var(--gutter-safe-top);
            /* font-size: 20px; */
            /* line-height: 1.3em; */
            height: 100%;
            overflow: auto;
        }

        main {
            max-width: 600px;
            margin: 0 auto;
            /* text-align: center; */
        }

        range-slider {
            flex-grow: 1;
        }

    `}

    firstUpdated(){
        AppInstaller.canInstallPromise.then(e=>this.update())
    }

    render(){return html`
        <main>
        <form-handler .model=${this.model} store="settings" .onChange=${this.onChange.bind(this)}>
        <b-grid cols-mobile=1>

            <b-text-divider bottom colspan xxl xbold pad>
                Settings

                <b-btn color="Theme" clear icon="plus" slot="right" @click=${addPace}>Add Pace</b-btn>
            </b-text-divider>
        
            <form-control key="theme" material="filled" label="Theme">
                <select-field .options=${Themes} show-empty></select-field>
            </form-control>

            <form-control key="theme_accent" material="filled" label="Accent">
                <select-field .options=${ThemeColors} show-empty></select-field>
            </form-control>

            <form-control key="scroll_dir" material="filled" label="Scroll Direction" ?hidden=${device.isTouch}>
                <select-field .options=${ScrollDir} show-empty></select-field>
            </form-control>

            <form-control key="touch_dir" material="filled" label="Touch Direction">
                <select-field .options=${TouchDir} show-empty></select-field>
            </form-control>

            <b-grid cols=1 colspan gap=" ">
                <b-text block semibold sm>Pace font size</b-text>
                <form-control key="font_size">
                    <range-slider slot="control" step=.1 min=.5 max=2 value=1></range-slider>
                    <b-text slot="prefix" semibold color="text">0.5&nbsp;&nbsp;</b-text>
                    <b-text slot="suffix" semibold color="text">&nbsp;&nbsp;2</b-text>
                </form-control>
            </b-grid>

            <div>
                <check-box type="switch" key="hide_add" label="Hide floating add button"></check-box>
            </div>

            <b-hr colspan thick short></b-hr>

            ${AppInstaller.canInstall?html`
            <b-text colspan align="center">
                <b-btn clear lg color="theme" icon="${device.isMobile?'install_mobile':'install'}" 
                    @click=${AppInstaller.install}>Install App</b-btn>
            </b-text>
            `:''}

            
        
        </b-grid>
        </form-handler>
        </main>
    `}

    onChange(changes){

        if( changes.theme != undefined || changes.theme_accent != undefined ){
            settings.applyTheme()
        }

        window.emitEvent('settings-change', settings)
    }

})

export default customElements.get('s-settings')



export async function addPace(e){
    
    let menu = [
        {label: 'Add New', val: 'add', icon: 'plus'},
        {divider: 'Saved Distances'}
    ].concat(Coll.filter(m=>m.isHidden).map(m=>{
        return {label: m.displayLabel, val: m}
    }))

    let selected = await new Menu(menu).popOver(e.currentTarget, {inverse: true})

    if( !selected ) return
    if( selected.val == 'add' )
        editPace({detail:{}})
    else
        selected.val.save('hide', null)
}

export async function editPace(e){

    let {model} = e.detail

    let pace = await Dialog.prompt({
        prompts: [
            {label: 'Distance', placeholder: '0', key: 'distance', pattern: 'decimal', type: 'number'},
            {label: 'Unit', options: ['meters', 'miles', 'kilometers'], key: 'unit'},
            {label: 'Label', placeholder: 'optional', key: 'label'},
        ],
        val: model ? model.toJSON() : {unit: 'meters'}
    }).modal()

    if( !pace ) return

    if( typeof pace.distance == 'string' )
        pace.distance = parseFloat(pace.distance.trim())

    if( !pace.distance ) return
    if( isNaN(pace.distance) )
        throw new UIDeniedError('Distance is not a valid number')

    pace.ts = new Date().getTime()

    if( model )
        model.save(pace)
    else
        Coll.create(pace)
}