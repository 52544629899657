import { LitElement, html, css } from 'lit'
import Impetus from 'impetus'
import {roundTo, round} from 'bui/util/math'
import './calc-val'
import Coll, {secondsAt, settings} from './models'
import {addPace, editPace} from './settings'

customElements.define('s-calc', class extends LitElement{

    static listeners = {
        coll: {'add remove change:hide': 'requestUpdate'}
    }

    static get properties(){ return {
        value: {type: Number},
        seconds: {type: Number}
    }}

    static get title(){ return 'Calc' }
    static get icon(){ return 'logo' }

    static get styles(){return css`
        :host {
            display: grid;
            position:relative;
            height: 100%;
            
        }

        c-calc-val {
            font-size: calc(var(--font-size, 1) * 3vmax);
        }

        @media (max-width: 599px){


            b-flex {
                column-gap: 1em;
            }
        }

        b-flex {
            height: 100%;
            box-sizing: border-box;
            overflow: hidden;
            padding-top: calc(.5em + var(--gutter-safe-top));
            padding-bottom: 1em;
            /* row-gap: 1em;
            justify-content: space-evenly; */
        }
    `}

    constructor(){
        super()
        this.addEventListener('wheel', this.onScroll)
        this.addEventListener('pointerdown', this.onPointerDown)
        // this.addEventListener('touchmove', this.onTouchMove)

        this.touchScroller = new Impetus({
            source: this,
            update: this.onTouchScroll.bind(this),
            multiplier: 4,
            boundX: [0, Infinity],
            boundY: [0, Infinity]
        })

        this.roundTo =1 // 1/2
        this.seconds = secondsAt()
        this.value = this.seconds * 100

        this.applySettings()

        window.addEventListener('settings-change', this.applySettings.bind(this))

        this.coll = Coll   
    }

    applySettings(){
        let s = settings() 

        this._scrollDir = s.scroll_dir
        this._touchDir = s.touch_dir
        this._baseDist = s.base_dist || 400

        if( s.font_size )
            this.style.setProperty('--font-size', (s.font_size||1))
        else
            this.style.removeProperty('--font-size')
        

        this.requestUpdate()
    }

    set value(val){
        let oldVal = this.value
        this.__value = val
    
        this.seconds = roundTo(this.value / 100, this.roundTo)

        this.requestUpdate('value', oldVal)
    }
    
    get value(){ return this.__value}

    onPointerDown(){
        this.touchScroller.setValues(this.value, this.value) // make sure it's in sync with mouse wheel
    }

    onTouchScroll(x, y){
        let v = x

        if( this._touchDir == 'vert' ){
            v = y
        }

        this.value = v < 0  ? 0 : (round(v, 0))
        this.cacheSeconds()
    }

    onScroll(e){

        let v = e.deltaY

        if( !this._scrollDir ){
            v = v * -1 // vert natural

        }else if( this._scrollDir == 'horz' ){
            v = e.deltaX
        }

        let value = this.value + (v)

        if( value < 0 ) value = 0

        this.value = Math.round(value)
        this.cacheSeconds()
    }

    cacheSeconds(){
        clearTimeout(this._cacheSeconds)
        this._cacheSeconds = setTimeout(()=>{
            secondsAt(this.seconds)
        }, 1000)
    }

    // get seconds(){
    //     return roundTo(this.value / 100, 10)
    //     // return roundTo(this.value / 100, 2).toFixed(1)
    // }

    render(){return html`

        <b-flex col center gap="2" wrap @edit=${editPace} @change-dist-base=${this.changeBaseDist}>

            <!-- <b-text body ?hidden=${settings().hide_howto}>
                <b-text block lg xbold>How to use</b-text>
                <b-text>Scroll/drag to change the times. Go to settings to alter the scroll direction.</b-text>
            </b-text> -->

            <!-- <b-text lg muted>${this.value}</b-text> -->
            ${this.coll.map(m=>m.isHidden?'':html`
                <c-calc-val .model=${m}
                    baseSeconds=${this.seconds} 
                    baseDistance=${this._baseDist}
                ></c-calc-val>
            `)}

        </b-flex>

        <b-btn fab color="theme-gradient" icon="add" @click=${addPace} ?hidden=${settings().hide_add}></b-btn>
    `}

    changeBaseDist(e){
        
        let s = settings()
        let old_base_dist = s.base_dist || 400
        s.base_dist = e.detail.base_dist

        settings(s) // save

        window.emitEvent('settings-change', settings)

        this.value = this.value * (s.base_dist / old_base_dist)
    }

})

export default customElements.get('s-calc')