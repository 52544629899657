import { Collection, Model } from "backbone";
import store from 'bui/util/store'
import {roundTo, round} from 'bui/util/math'
import colorScheme from 'bui/util/color-scheme'
// import {secondsToTime} from 'bui/util/time'

export const Default_Paces = [
    {distance:400},
    {distance:800},
    {distance:1500},
    {distance:1, unit: 'miles', label: "Mile"},
    {distance:3000, label: "3K"},
    {distance:3200, unit: '3200', label: '3200m', hide: true},
    {distance:5000, label: "5K"},
    {distance:10000, label: "10K"},
    {distance:15000, label: "15K", hide: true},
    {distance:13.1, label: "Half-Marathon", unit: 'miles', hide: true},
    {distance:26.2, label: "Marathon", unit: 'miles', hide: true},
]

const cache = store.create('paces', Default_Paces)
export const settings = store.create('settings', {})
export const secondsAt = store.create('seconds', 60)

settings.applyTheme = function(){
    colorScheme.apply({
        colorizeFaviconComposition: 'source-atop',
        theme: (settings().theme||'auto'), 
        accent: settings().theme_accent,
        watch: true
    })
}

class Paces extends Collection {

    constructor(){
        super(cache())
    }

    get model(){ return Pace }

    save(...args){
        this.set(...args)
        cache(this.toJSON())
    }

    comparator(m){
        return m.distanceMeters
    }

}

class Pace extends Model {

    get isHidden(){ return !!this.get('hide') }

    get displayLabel(){
        let units = this.units
        // if( units == 'kilometers') units = 'km'
        return this.get('label') || (this.get('distance')+' '+units)
    }

    get units(){ return this.get('unit') || 'meters' }

    get distanceMeters(){
        let dist = this.get('distance')
        
        if( this.get('unit') == 'miles' )
            dist = roundTo(dist / 0.000621371, 2)
        else if( this.get('unit') == 'kilometers' )
            dist = roundTo(dist * 1000, 2)
        
        return dist
    }

    save(...args){
        this.set(...args)
        this.collection.save()
    }

    destroy(...args){
        let coll = this.collection
        super.destroy(...args)
        coll.save()
    }

}

export default new Paces()