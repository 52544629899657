import { LitElement, html, css } from 'lit'
import App from 'bui/app/tabbar'
import AppInstaller from 'bui/app/installer'
import {mediaQuery} from 'bui/util/mediaQueries'
import 'bui/elements/flex'
import 'bui/elements/text'
import 'bui/elements/grid'
import './calc'
import './settings'
import './about'

customElements.define('s-app', class extends App{

    static get styles(){return [super.styles, css`
        footer {
            text-align: center;
            align-items: center;
            padding-bottom: 0.5em;
            justify-content: flex-end;
            flex-direction: column;
            display: none;
        }

        ${mediaQuery('b-app-landscape', css`
            footer {
                display: flex;
            }
        `)}
    `]}

    constructor(...args){
        super(...args)
        AppInstaller.canInstallPromise.then(e=>this.update())

        AppInstaller.promptInstall({once: true, delay: 2000})
    }

    get views(){return html`
        s-calc
        s-settings
        s-about
        

        ${AppInstaller.canInstall?html`
        <footer col bottom slot="menu:after">
            <b-btn color="theme" clear @click=${AppInstaller.install}>
                Install
            </b-btn>
        </footer>
        
        `:''}
    `}

    get shouldShowSearch(){ return false }

})

export default customElements.get('s-app')