import { LitElement, html, css } from 'lit'
import Dialog from 'dialog'
import Menu from 'menu'
import {secondsToTime} from 'bui/util/time'
import {settings} from './models'

customElements.define('c-calc-val', class extends LitElement{

    static listeners = {
        model: {'change': 'requestUpdate'}
    }

    static get properties(){return {
        baseDistance: {type: Number},
        baseSeconds: {type: Number},
    }}

    constructor(){
        super()
        this.baseDistance = 400
    }

    static get styles(){return css`
        :host {
            display: block;
            position:relative;
        }

        /* [xxl] {
            font-size: var(--font-size, 1em);
        } */

        [gradient] {
            font-size: .8em;
        }

        @media (max-width: 699px){
            [gradient] {
                font-size: 1rem;
            }
        }

        [name="timer"] {
            vertical-align: bottom;
        }

        :host(:not([is-base])) [name="timer"] {
            display: none;
        }

        :host(.popover-open) {
            border-radius: 8px;
            box-shadow: 0 0 0 8px var(--theme-bgd), 0 0 0 10px var(--theme);
        }

        :host(.popover-open) c-time-dig {
            color: var(--theme);
        }        
    `}

    async clickMenu(e){
        await new Menu([
            (this.isBaseDist?{
                divider: 'Base Distance'
            }:{
                label: 'Make Base Distance', fn: 'makeBase', icon: 'timer'
            }),
            {label: 'Hide', fn: 'hide', icon: 'visibility_off'},
            {label: 'Edit', fn: 'edit', icon: 'edit'},
            '-',
            {label: 'Delete', fn: 'destroy', icon: 'trash'}
        ], {handler: this}).popOver(this, {inverse: true})
    }
    
    makeBase(){ 
        this.emitEvent('change-dist-base', {base_dist: this.model.distanceMeters})
    }

    edit(){
        this.emitEvent('edit', {model: this.model})
    }

    hide(){
        this.model.save({hide: true})
    }

    async destroy(){
        if( await Dialog.confirmDelete().popOver(this) )
            this.model.destroy()
    }

    get value(){
        return (this.model.distanceMeters / this.baseDistance) * this.baseSeconds
    }

    get isBaseDist(){ return this.baseDistance == this.model.distanceMeters }

    shouldUpdate(){
        this.seconds = this.value
        this.time = secondsToTime(this.value)
        return true
    }

    updated(){
        this.toggleAttribute('is-base', this.isBaseDist)
    }

    render(){return html`
        
        <b-text block>
        <b-text bold gradient >
            <b-icon name="timer"></b-icon>
            ${this.model.displayLabel}</b-text>
        </b-text>
        
        <b-text xxl xbold>

            ${this.seconds < 100?html`
                <c-time-dig num=${Math.floor(this.seconds)} sep="."></c-time-dig>
                <c-time-dig num=${this.time.ms}></c-time-dig>
            `:html`

                <c-time-dig num=${this.time.h} ?hidden=${!this.time.h}></c-time-dig>
                <c-time-dig num=${this.time.m} ?hidden=${!this.time.h&&!this.time.m}></c-time-dig>
                <c-time-dig num=${this.time.s}></c-time-dig>

            `}
            

            <b-numeral format="00:00" .num=${this.value}></b-numeral>
        </b-text>
        
        <!-- <b-text block>${this.seconds}</b-text> -->
    `}

})

export default customElements.get('c-calc-val')

// import { LitElement, html, css } from 'lit'

customElements.define('c-time-dig', class extends LitElement{

    static get properties(){ return{
        num: {type: Number},
        sep: {type: String}
    }}

    static get styles(){return css`
        /* :host {
            display: inline-block;
            position:relative;
        } */

        [part="num"] {
            display: inline-block;
        }

        [part="sep"] {
            margin: 0 -.125em;
        }

        :host(:last-of-type) [part="sep"] {
            display: none;
        }
    `}

    constructor(){
        super()
        this.sep = ':'
    }

    get val(){
        return String(this.num).padStart(2, '0')
    }

    render(){return html`
        
        <b-text tnum ?_muted=${this.num==0} part="num">${this.val}</b-text>

        <b-text muted part="sep">${this.sep}</b-text>
    `}

})

// export default customElements.get('c-time-dig')