
import './style.less';

import router from 'bui/router'
router.config({
    root: '/',
    urlPattern: {
        segmentValueCharset: 'a-zA-Z0-9-_~ %.'
    }
})

import 'bui/helpers/app-height-fix'
import 'bui/helpers/fetch'
import 'bui/helpers/lit'
// import 'bui/helpers/backbone/attr-types'
// import 'bui/helpers/backbone/singleton'
// import 'bui/helpers/backbone/promises'
import 'bui/app/error-handler'
// import 'bui/app/background-resume'
// import 'bui/app/network-monitor'
import 'bui/app/tabbar'
import './icons'

window.goTo = function(path, props){
    router.goTo(path, props)
}

import device from 'bui/util/device'

import './app'
import './sw'
import {settings} from './models' // TODO: support changing theme mode


window.addEventListener('DOMContentLoaded', e=>{
    device.applyClasses()

    settings.applyTheme()

    document.body.appendChild(document.createElement('s-app'))
})
