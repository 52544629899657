import Icon from 'bui/elements/icon'
import PWAIcons from 'bui/elements/icon/sets/pwa'

// https://fonts.google.com/icons
const MaterialIcons = [
    ['settings_material', require(`@material-icons/svg/svg/settings/baseline.svg`)],
    // ['trash', require(`@material-icons/svg/svg/delete/baseline.svg`)],
    ['account_circle', require(`@material-icons/svg/svg/account_circle/baseline.svg`)],
    ['badge', require(`@material-icons/svg/svg/badge/baseline.svg`)],

    ['timer', require(`@material-icons/svg/svg/timer/baseline.svg`)],
    ['info', require(`@material-icons/svg/svg/info/baseline.svg`)],

    ['logo', require(`../public/icons/logo.svg`)],
    ['add', require('@material-icons/svg/svg/add/baseline.svg')],
    ['attention-circle', require('@material-icons/svg/svg/error/baseline.svg')],
    ['edit', require('@material-icons/svg/svg/edit/baseline.svg')],
    ['tune', require('@material-icons/svg/svg/tune/baseline.svg')],
    ['visibility_off', require('@material-icons/svg/svg/visibility_off/baseline.svg')]
]

Icon.register(...MaterialIcons, ...PWAIcons)