import { LitElement, html, css } from 'lit'
import AppInstaller from 'bui/app/installer'
import device from 'bui/util/device'

customElements.define('s-about', class extends LitElement{
    
    static get title(){ return 'About' }
    static get path(){ return 'about' }
    static get id(){ return 'about' }
    static get icon(){ return 'info' }

    static get styles(){return css`
        :host {
            display: block;
            position:relative;
            padding: var(--gutter);
            padding-top: var(--gutter-safe-top);
            height: 100%;
            overflow: auto;
        }

        @media (min-width: 600px) {
            :host {
                font-size: 20px;
                line-height: 1.3em;
            }
        }

        b-icon {
            color: var(--theme);
            font-size: 6em;
            display: var(--about-logo-svg);
        }

        img {
            display: var(--about-logo-img);
        }

        main {
            max-width: 600px;
            margin: 0 auto;
            text-align: center;
        }

        .share {
            position: absolute;
            top: calc(1em + var(--safe-top));
            right: 1em;
        }
    `}

    firstUpdated(){
        AppInstaller.canInstallPromise.then(e=>this.update())
    }

    render(){return html`

        <main>

            <b-btn class="share" clear color="theme" @click=${this.share}>Share</b-btn>

        <b-flex col center gap="1">
            
            <b-icon name="logo"></b-icon>
            <img src="/icons/desktop-256.png" style="width: 6em">

            <b-text gradient xxl block xbold>SplitCalc.app</b-text>

            <b-text md xbold body>An effortless pace calculator for runners, swimmers, and other distance athletes.</b-text>

            ${AppInstaller.canInstall?html`
                <b-btn lg color="theme" @click=${AppInstaller.install}
                    icon="${device.isMobile?'install_mobile':'install'}">Install App</b-btn>
            `:''}
        </b-flex>

        

        <b-hr short thick pad="md"></b-hr>

        <b-text block body>
            The other day I recalled using a handheld pace slider made of plastic and how easy it was to quickly find paces/splits.
            <br><br>
            I figured making a web app with the same ability would be fairly easy to implement – so here it is.
        </b-text>

        <br>

        <b-text block>
            Built by <b-text href="https://kevinjantzer.com">Kevin Jantzer</b-text>
        </b-text>

        <b-hr short thick pad="md"></b-hr>

        <b-text body>Have a question, comment, or request?
            <br>Shoot me an email: <b-text href="kevin@jantzer.dev?subject=${location.hostname}">kevin@jantzer.dev</b-text>
        </b-text>


        <br><br>
        <b-flex>

            <b-btn color="" outline href="https://cash.app/$kjantzer">☕️ Buy me a coffee</b-btn>

            <b-btn color="" outline @click=${this.reload}>Reload App</b-btn>
            
        </b-flex>

        <!-- <b-hr short thick pad="md"></b-hr> -->
        <br><br>

        <b-flex col style="text-align: left">

            <b-text-divider thick xbold lg>Changelog</b-text-divider>

            <b-text body>
            <p><b>June 20, 2023</b><br>
            - Bug fix for Chrome 114 browser<br>
            - Font size of results scales with window size<br>
            </p>
            </b-text>

            <b-text body>
            <p><b>June 12, 2022</b><br>
            - Theme support<br>
            - Direction settings<br>
            - Pace font size can be adjusted<br>
            - Paces can be hidden instead of deleted<br>
            - Base distance can be changed. Scrolling second changes are based on this distance.
            </p>
            </b-text>

            <b-text body>
            <p><b>June 5, 2022</b><br>
            🎉 Rewritten from the ground up. Now installable as an app (add to home screen on iOS). Dark mode automatically applied in the evening.</i></p>
            </b-text>

            <b-text body>
            <p><b>Mar 24, 2015</b><br>
            Initial release</p>
            </b-text>

        </b-flex> 

        </main>
    `}

    reload(){
        location.reload(true)
    }

    async share(){

        if( !navigator.canShare )
            throw new UIAlertError('You device does not support the share feature')
        
        const image = await fetch('/img/preview.jpg');
        const blob = await image.blob();
        const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });

        navigator.share({
            title: 'SplitCalc.app',
            text: `An effortless pace calculator for runners, swimmers, and other distance athletes`,
            url: 'https://splitcalc.app',
            files: [file]
        }).catch(err=>{})
    }

})

export default customElements.get('s-about')